import {
    GetPlantsQuery,
    UserPlantInfo
} from '../../graphql/generated/autogenerated'
import { GET_PLANTS } from '../../graphql/queries/garden'
import store, {
    setActivePlants,
    setGardenActionDisabled,
    setGardenLoading,
    setPlantedPlants,
    setProducts,
    setObjects
} from '../../store'
import { getApolloClient } from '../ApolloClient'
import isTokenExpired from '../isTokenExpired'
import selectIsLoggedIn from '../selectIsLoggedIn'
import { captureException } from './../../utils'

export const initializeGarden = async () => {
    return new Promise((resolve) => {
        const state = store.getState()
        if (
            selectIsLoggedIn(state.auth) &&
            !isTokenExpired(state.auth.thriveToken)
        ) {
            resolve(fetchAndStoreGarden())
        } else {
            const unsubscribe = store.subscribe(() => {
                if (store.getState().user.userId) {
                    unsubscribe()
                    resolve(fetchAndStoreGarden(true))
                }
            })
        }
    })
}

export const fetchAndStoreGarden = async (subscribeTriggered?: boolean) => {
    const apolloClient = getApolloClient()
    return apolloClient
        .query<GetPlantsQuery>({
            query: GET_PLANTS
        })
        .then((response) => dispatchGarden(response.data))
        .catch((err) => {
            store.dispatch(setGardenLoading(false))
            captureException(err, {
                message: `fetchAndStoreGarden${
                    subscribeTriggered ? ' subscribe' : ''
                } failed`
            })
        })
}

const dispatchGarden = async (gardenData: GetPlantsQuery) => {
    const products = gardenData?.retail?.getProducts
    const plants = gardenData?.retail?.getPlants
    const objects = gardenData?.retail?.getObjects

    if (products?.length > 0) {
        const [planted, active]: [UserPlantInfo[], UserPlantInfo[]] =
            plants.reduce(
                ([p, a], plant) => {
                    return plant.plantedGardenId
                        ? [[...p, plant], a]
                        : [p, [...a, plant]]
                },
                [[], []]
            )

        store.dispatch(setActivePlants(active))
        store.dispatch(setPlantedPlants(planted))
        store.dispatch(setProducts(products))
        store.dispatch(setObjects(objects))
        store.dispatch(setGardenLoading(false))
        store.dispatch(setGardenActionDisabled(!active?.[0]?.canBeWatered))
    }
}
