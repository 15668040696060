import { captureException } from '..'
import { BalanceInfo } from '../../graphql/generated/autogenerated'
import { GET_WALLET_BALANCE } from '../../graphql/queries/wallet'
import store from '../../store'
import { setTokens, setWallet } from '../../store/slices/wallet'
import { getApolloClient } from '../ApolloClient'
import isTokenExpired from '../isTokenExpired'
import selectIsLoggedIn from '../selectIsLoggedIn'

export const initializeWallet = async () => {
    return new Promise((resolve) => {
        const state = store.getState()
        if (
            selectIsLoggedIn(state.auth) &&
            !isTokenExpired(state.auth.thriveToken)
        ) {
            resolve(fetchAndStoreWallet())
        } else {
            const unsubscribe = store.subscribe(() => {
                if (store.getState().user.userId) {
                    unsubscribe()
                    resolve(fetchAndStoreWallet(true))
                }
            })
        }
    })
}

export const fetchAndStoreWallet = async (subscribeTriggered?: boolean) => {
    const apolloClient = getApolloClient()
    return apolloClient
        .query({
            query: GET_WALLET_BALANCE
        })
        .then((response) => dispatchBalance(response.data.retail.getBalance))
        .catch((err) => {
            captureException(err, {
                message: `fetchAndStoreWallet${
                    subscribeTriggered ? ' subscribe' : ''
                } failed`
            })
        })
}

const dispatchBalance = async (balanceData) => {
    store.dispatch(setWallet(balanceData))
    const tokenBalance = balanceData.find(
        (b: BalanceInfo) => b.currencyCode === 'TKN'
    )
    if (tokenBalance) {
        store.dispatch(setTokens(tokenBalance.amount))
    }
}
