import { createSlice } from '@reduxjs/toolkit'

export type NPSSurveyState = {
    npsLastServed: number | null
}

const initialState: NPSSurveyState = {
    npsLastServed: null
}

export const npsSurveySlice = createSlice({
    name: 'npsSurvey',
    initialState,
    reducers: {
        setNpsLastServed: (state, { payload }: { payload: number }) => {
            state.npsLastServed = payload
        }
    }
})

export const { setNpsLastServed } = npsSurveySlice.actions

export default npsSurveySlice.reducer
