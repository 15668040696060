import { useLocation } from 'react-router-dom'

export const useHasReferrer = (referrer: string | string[]) => {
    const { state } = useLocation()
    return (
        (Array.isArray(referrer)
            ? referrer.includes(state?.referrer)
            : state?.referrer === referrer) && !!state?.from
    )
}
