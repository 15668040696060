import React, { useState, useEffect } from 'react'

import {
    Breadcrumb,
    setBreadcrumbs as setBreadcrumbsActionCreator,
    useAppSelector,
    useAppDispatch
} from '../../store'

type UseBreadcrumbs = (
    b?: Breadcrumb[]
) => [Breadcrumb[], (b: Breadcrumb[]) => void]

export const useBreadcrumbs: UseBreadcrumbs = (componentCrumbs) => {
    const reduxCrumbs = useAppSelector((state) => state.breadcrumbs)
    const dispatch = useAppDispatch()

    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(() => {
        if (componentCrumbs) {
            dispatch(setBreadcrumbsActionCreator(componentCrumbs))
            return componentCrumbs
        } else {
            return reduxCrumbs
        }
    })

    useEffect(() => {
        setBreadcrumbs(reduxCrumbs)
    }, [reduxCrumbs])

    return [
        breadcrumbs,
        (newCrumbs) => {
            setBreadcrumbs(newCrumbs)
            dispatch(setBreadcrumbsActionCreator(newCrumbs))
        }
    ]
}
