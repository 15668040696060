// e.g. "America/New_York"
export type TzOption = string
export const isTzOption = (s: string): s is TzOption => /\w+\/\w+/.test(s)

// e.g. "New York"
export type City = string
export const isCity = (s: string): s is City => /^[A-Za-z0-9\s]+$/.test(s)
export const toCity = (t: TzOption): City =>
    isTzOption(t) ? t.split('/')[1].replace('_', ' ') : ''

// e.g. "+01:00"
export type Offset = string
export const isOffset = (s: string): s is Offset =>
    // + or - followed by an hh:mm offset
    /^[+|-][0-9]{2}:[0-9]{2}$/.test(s)
export const toOffset = (t: TzOption): Offset => {
    if (!isTzOption(t)) return
    const matched = Intl.DateTimeFormat(undefined, {
        timeZone: t,
        timeZoneName: 'longOffset'
    })
        .format(new Date())
        .match(/GMT(.+)$/)

    return matched ? matched[1] : '+00:00'
}

export type OffsetWithCity = { offset: Offset; city: City }
