import './global.css'
import { ThriveAppInit } from './index'

export * from './hooks'
export * from './enums'
export * from './utils'
export * from './components'
export { default as store } from './store'
export * from './store'
export { ThriveApplication } from './ThriveApplication'
export { gql } from '@apollo/client'
// Temporary for now
export * from './mobile-communication'
export * as Apollo from '@apollo/client'
// Don't run in common js bundle as this is used by tests and is hella slow
if (!process.env.COMMON_JS_BUNDLE) {
    ThriveAppInit()
}
