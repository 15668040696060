import {
    Offset,
    City,
    OffsetWithCity,
    isTzOption,
    toCity,
    toOffset
} from './types'

export const getOffsetFromTimezone = (timeZone: string): OffsetWithCity =>
    isTzOption(timeZone)
        ? {
              city: toCity(timeZone),
              offset: toOffset(timeZone)
          }
        : undefined

export type UseTimezoneOptionsReturn = Record<Offset, City[]>
export const useTimezoneOptions = (): UseTimezoneOptionsReturn =>
    (Intl as any)
        .supportedValuesOf('timeZone')
        .map(getOffsetFromTimezone)
        .reduce(
            (acc: UseTimezoneOptionsReturn, { offset, city }) => ({
                ...acc,
                [offset]: (acc[offset] || []).concat(city).sort()
            }),
            {}
        )

export * from './types'
