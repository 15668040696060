import { Parser } from './types'
import getStorageItem from './getStorageItem'

export const initializeValue = <T>(
    key: string,
    initialValue: T,
    storage: Storage,
    parser: Parser<T>
): T => {
    const storageValue = getStorageItem(key, storage, parser)

    return storageValue !== null ? storageValue : initialValue
}

export default initializeValue
