export enum ThriveAppPlatform {
    ANDROID = 'hybrid-android',
    IOS = 'hybrid-ios',
    MS_TEAMS = 'msteams',
    WEB = 'web',
    WEBEX = 'webex',
    NICE = 'nice',
    GENESYS = 'genesys',
    WEBEX_TST = 'webex-tst'
}
