export enum HybridEventType {
    HANDSHAKE = 'HANDSHAKE',
    LOAD_NATIVE = 'LOAD_NATIVE',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    NAVIGATE = 'NAVIGATE',
    SET_NAVIGATION_STATE = 'SET_NAVIGATION_STATE',
    REQUEST_DEVICE_ID = 'REQUEST_DEVICE_ID',
    REQUEST_MOBILE_APP_VERSION = 'REQUEST_MOBILE_APP_VERSION',
    UPDATE_LOCALE = 'UPDATE_LOCALE',
    SEND_USER_ID = 'SEND_USER_ID',
    WEB_BRIDGE_READY = 'WEB_BRIDGE_READY',
    SEND_WEB_STATE = 'SEND_WEB_STATE',
    REQUEST_OPEN_DEVICE_SETTINGS = 'REQUEST_OPEN_DEVICE_SETTINGS',
    REQUEST_PUSH_NOTIFICATION_PERMISSION_PROMPT = 'REQUEST_PUSH_NOTIFICATION_PERMISSION_PROMPT',
    REQUEST_PUSH_NOTIFICATION_PERMISSION_STATUS = 'REQUEST_PUSH_NOTIFICATION_PERMISSION_STATUS',
    REQUEST_APPLE_HEALTH_STATUS = 'REQUEST_APPLE_HEALTH_STATUS',
    REQUEST_APPLE_HEALTH_PROMPT = 'REQUEST_APPLE_HEALTH_PROMPT',
    REQUEST_APPLE_HEALTH_STEPS_DATA = 'REQUEST_APPLE_HEALTH_STEPS_DATA',
    REQUEST_APPLE_HEALTH_SLEEP_DATA = 'REQUEST_APPLE_HEALTH_SLEEP_DATA',
    SHARED_CONTENT_RECEIVED = 'SHARED_CONTENT_RECEIVED'
}
export enum HybridNavigationTab {
    today = 'today',
    challenges = 'challenges',
    learn = 'learn',
    reset = 'reset'
}

export interface HybridMessage {
    type: HybridEventType
    value?: any
}
