import { useState, useEffect, useCallback, useMemo } from 'react'
import { UseStorageOptions, UseStorageResult } from './types'
import { DEFAULT_USE_STORAGE_OPTIONS } from './constants'
import initializeValue from './initializeValue'
import parseItem from './parseItem'
import setStorageItem from './setStorageItem'

export const useStorage = <T>(
    key: string,
    initialValue: T,
    storage: Storage,
    options?: UseStorageOptions<T>
): UseStorageResult<T> => {
    const {
        comparator = DEFAULT_USE_STORAGE_OPTIONS.comparator,
        parser = DEFAULT_USE_STORAGE_OPTIONS.parser,
        serializer = DEFAULT_USE_STORAGE_OPTIONS.serializer
    } = options ?? {}

    const [value, setValue] = useState<T>(() =>
        initializeValue(key, initialValue, storage, parser)
    )

    const handleStorageChanges = useCallback(
        (event: StorageEvent) => {
            if (event.storageArea !== storage || event.key !== key) {
                return
            }

            const newValue = parseItem(event.newValue, parser)
            const isSame = comparator(value, newValue)

            if (!isSame) {
                setValue(newValue)
            }
        },
        [value, key, storage, parser, comparator]
    )

    useEffect(() => {
        window?.addEventListener('storage', handleStorageChanges)

        return () => {
            window?.removeEventListener('storage', handleStorageChanges)
        }
    }, [handleStorageChanges])

    const handleSetItem = useCallback(
        (newValue: T) => {
            setStorageItem(key, newValue, storage, serializer)
            setValue(newValue)
        },
        [key, storage, serializer]
    )

    const handleRemoveItem = useCallback(() => {
        storage.removeItem(key)
        setValue(null)
    }, [storage, key])

    return useMemo(
        () => [value, handleSetItem, handleRemoveItem],
        [value, handleSetItem, handleRemoveItem]
    )
}

export default useStorage
