import { gql } from '@apollo/client'

export const GET_LOCALE_TIMEZONE = gql`
    query getLocaleTimezone {
        identity {
            userAttributes {
                get {
                    name
                    value
                }
            }
        }
    }
`

export const GET_PROFILE = gql`
    query getProfile {
        identity {
            me {
                email
            }
        }
    }
`
