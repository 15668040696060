import { PropsWithChildren, useEffect, useState } from 'react'
import { isFeatureEnabled } from '../FeatureFlags'
import { addTrackingLabel } from '../DataDog'

export enum TRACKING_VARIANTS {
    BASELINE = 'baseline',
    VARIANT1 = 'variant1',
    VARIANT2 = 'variant2',
    VARIANT3 = 'variant3',
    VARIANT4 = 'variant4',
    VARIANT5 = 'variant5'
}

type ChildrenWrapper = (
    isEnabled: boolean,
    isReady: boolean,
    isVariant: TRACKING_VARIANTS
) => React.ReactNode

interface TrackedFeatureProps {
    feature: string
    defaultValue: boolean | TRACKING_VARIANTS
    checkUser?: boolean
    children: ChildrenWrapper
    extraCustomFields?: { [key: string]: any }
}

const TrackedFeature: React.FC<PropsWithChildren<TrackedFeatureProps>> = ({
    children,
    feature,
    defaultValue = false,
    checkUser = false,
    extraCustomFields = {}
}) => {
    const [isEnabled, setIsEnabled] = useState(
        defaultValue === false ? false : true
    )
    const [isVariant, setIsVariant] = useState<TRACKING_VARIANTS>(
        TRACKING_VARIANTS.BASELINE
    )
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        const checkFeatureEnabled = async () => {
            const result = await isFeatureEnabled(
                feature,
                defaultValue,
                checkUser,
                extraCustomFields
            )
            setIsEnabled(result === false ? false : true)
            setIsVariant(
                Object.values(TRACKING_VARIANTS).includes(
                    result as unknown as TRACKING_VARIANTS
                )
                    ? (result as unknown as TRACKING_VARIANTS)
                    : TRACKING_VARIANTS.BASELINE
            )
            setIsReady(true)

            addTrackingLabel(feature, result)
        }
        checkFeatureEnabled()
    }, [])

    return <>{children(isEnabled, isReady, isVariant)}</>
}

export default TrackedFeature
