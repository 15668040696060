export const loadState = (name) => {
    try {
        const data = localStorage.getItem(name)
        if (data === null) return undefined
        return JSON.parse(data)
    } catch (err) {
        return undefined
    }
}

export const saveState = (name, state) => {
    try {
        const data = JSON.stringify(state)
        localStorage.setItem(name, data)
    } catch (err) {
        console.error(err)
    }
}

export const removeState = (name) => {
    try {
        localStorage.removeItem(name)
    } catch (err) {
        console.error(err)
    }
}
