import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type globalAppVariablesState = {
    segmentInitialized: boolean
    branchInitialized: boolean
}

export const globalAppState: globalAppVariablesState = {
    segmentInitialized: false,
    branchInitialized: false
}
// Slice of the store that is NOT persisted in localStorage
// This is used to set variables to use within the other MFES
export const globalAppVariables = createSlice({
    name: 'globalAppVariables',
    initialState: globalAppState,
    reducers: {
        setSegmentInitialized: (state, { payload }: PayloadAction<boolean>) => {
            state.segmentInitialized = payload
        },
        setBranchInitialized: (state, { payload }: PayloadAction<boolean>) => {
            state.branchInitialized = payload
        }
    }
})

export const { setSegmentInitialized, setBranchInitialized } =
    globalAppVariables.actions

export default globalAppVariables.reducer
