import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { THEME } from '../../utils/Theme/constants'
import { WebApplicationLocale } from '../../utils/Intl'

export type SettingsState = {
    theme: THEME
    locale: WebApplicationLocale
    timezone: string
}

const initialState: SettingsState = {
    theme: THEME.THRIVE,
    locale: null,
    timezone: null
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTheme: (state, { payload }: PayloadAction<THEME>) => {
            state.theme = payload
        },
        setLocale: (
            state,
            { payload }: PayloadAction<WebApplicationLocale>
        ) => {
            state.locale = payload
        },
        setTimezone: (state, { payload }: PayloadAction<string>) => {
            state.timezone = payload
        }
    }
})

export const { setTheme, setLocale, setTimezone } = settingsSlice.actions

export default settingsSlice.reducer
