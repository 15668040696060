import { gql } from '@apollo/client'

export const GET_PENDING_NUDGES = gql`
    query getPendingNudges($input: PendingNudgesInput) {
        centralizedNotifications {
            pendingNudges(input: $input) {
                ... on UserPendingNudgesFetchFailure {
                    error
                }
                ... on UserPendingNudgesFetchSuccess {
                    result {
                        expiresAt
                        nudgeMetadata {
                            ... on TestNudge {
                                testCtaUrl
                                testValue
                            }
                            ... on SmartNudgeReset {
                                resetId
                            }
                            ... on InChallengeGoalReminder {
                                challenges {
                                    challengeDay
                                    challengeId
                                    challengeType
                                }
                            }
                        }
                        nudgeType
                        producedAt
                        sendDate
                        sendTime
                        sentAt
                        targetTimestamp
                        thriveUserId
                        whereToShow
                    }
                }
            }
        }
    }
`
