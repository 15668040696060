export function generateTeamsDeepLink(path: string) {
    const teamsUrl = process.env.MSTEAMS_CLOUD_APP_URL
    const appId = process.env.MSTEAMS_APP_ID

    const url = new URL(`${teamsUrl}/l/entity/${appId}/home`)

    if (path) {
        url.searchParams.append(
            'context',
            JSON.stringify({ subEntityId: path })
        )
    }

    return url
}
