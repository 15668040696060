import { MessageFormatElement } from 'react-intl'
import { Messages } from '.'

type PromiseParams = {
    resolve: (result: Record<string, MessageFormatElement[]>) => void
    reject: (err: unknown) => void
}

interface LangCache {
    result: Record<string, MessageFormatElement[]>
    ttl: number
    subscriptions: PromiseParams[]
}

function getCurrentTime(): number {
    return Date.now()
}

const langCache = new Map<string, LangCache>()
const oneMinuteFromNow = () => getCurrentTime() + 1000 * 60

const loadi18n = (cloudflareAppURL: string, lang: string) =>
    fetch(`${cloudflareAppURL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch((err) => {
            console.warn(err)
            throw err
        })

const freshLangRequest = async (
    key: string,
    cloudflareAppURL: string,
    lang: string,
    p: PromiseParams
) => {
    langCache.set(key, {
        result: null,
        ttl: oneMinuteFromNow(),
        subscriptions: []
    })
    try {
        const result = await loadi18n(cloudflareAppURL, lang)

        if (langCache.get(key).subscriptions.length > 0)
            langCache
                .get(key)
                .subscriptions.forEach((sub) => sub.resolve(result))

        langCache.set(key, {
            result,
            ttl: oneMinuteFromNow(),
            subscriptions: []
        })
        p.resolve(result)
    } catch (error: unknown) {
        if (langCache.get(key).subscriptions.length > 0)
            langCache.get(key).subscriptions.forEach((sub) => sub.reject(error))

        langCache.delete(key)
        p.reject(error)
    }
}

const langLoader = (
    key: string,
    cloudflareAppURL: string,
    lang: string
): Promise<Messages | null> =>
    new Promise((resolve, reject) => {
        if (langCache.has(key) && langCache.get(key).ttl > getCurrentTime()) {
            if (langCache.get(key).result === null) {
                langCache.get(key).subscriptions.push({ resolve, reject })
            } else {
                resolve(langCache.get(key).result)
            }
        } else {
            freshLangRequest(key, cloudflareAppURL, lang, { resolve, reject })
        }
    })

export default (cloudflareAppURL: string) => {
    return (lang: string) => {
        const cacheKey = `${cloudflareAppURL}:${lang}`
        return langLoader(cacheKey, cloudflareAppURL, lang)
    }
}
