import { match } from '@formatjs/intl-localematcher'
import { getCookie } from '../Cookie'

function localeMatcher(
    availableLocales: readonly string[],
    availableLanguages: readonly string[],
    defaultLocale: string,
    languagesToLocaleMap: { [index: string]: string },
    search: string,
    storage: Storage,
    localeSetting: string,
    systemLanguages: readonly string[]
): string {
    const searchParams = new URLSearchParams(search)
    const fromSearchParams = searchParams.get('locale')

    let fromLocalStorage: string = null
    try {
        fromLocalStorage = storage.getItem('thrive-locale')
        // eslint-disable-next-line no-empty
    } catch {}

    // Get the cookie that was set by mobile and use this in the matcher for language
    const localeFromMobile = getCookie('thrive-locale')?.replace('_', '-')

    const requestedLocales = [
        fromSearchParams,
        fromLocalStorage,
        localeSetting,
        localeFromMobile,
        ...systemLanguages
    ].filter((l) => l != null)

    if (requestedLocales.includes('pseudo')) return 'pseudo'

    try {
        const matchedLocale = match(
            requestedLocales,
            [...availableLocales, ...availableLanguages],
            defaultLocale,
            { algorithm: 'best fit' }
        )

        return languagesToLocaleMap[matchedLocale] ?? matchedLocale
    } catch (err) {
        console.warn(`Invalid Locale Passed: ${err}`)
        return defaultLocale
    }
}

export { localeMatcher }
