import {
    WEEKDAYS,
    WEEKDAYS_MIN,
    WEEKDAYS_SHORT,
    MONTHS,
    MONTHS_SHORT,
    REGEX_FORMAT,
    REGEX_PARSE,
    INVALID_DATE_STRING
} from './constants'

const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000

/* utility for handling common date functionality based loosely on dayjs */
const utcOffset = (d: Date) => -Math.round(d.getTimezoneOffset() / 15) * 15

const padStart = (string, length, pad) => {
    const s = String(string)
    if (!s || s.length >= length) return string
    return `${Array(length + 1 - s.length).join(pad)}${string}`
}

const padZoneStr = (d: Date) => {
    const negMinutes = -utcOffset(d)
    const minutes = Math.abs(negMinutes)
    const hourOffset = Math.floor(minutes / 60)
    const minuteOffset = minutes % 60
    return `${negMinutes <= 0 ? '+' : '-'}${String(hourOffset).padStart(
        2,
        '0'
    )}:${String(minuteOffset).padStart(2, '0')}`
}

/**
 * Parse a date string representation or a date object to return a new instant of a date
 * @param date String representation of a date or an actual Date object
 * @param utc Flag as to whether the date should be interpreted as UTC or locale browser timezone
 * @returns Date object
 */
export const parseDate = (date?: string | Date, utc = false): Date => {
    if (date === null) return new Date(NaN) // null is invalid
    if (date === undefined) return new Date() // today if there is no date passsed in
    if (date instanceof Date) return new Date(date) // return date clone

    // If not zero UTC offset
    if (typeof date === 'string' && !/Z$/i.test(date)) {
        const d = date.match(REGEX_PARSE)
        if (d) {
            const month = typeof d[2] === 'string' ? Number(d[2]) - 1 : 0
            const day = typeof d[3] === 'string' ? Number(d[3]) : 1
            const year = Number(d[1])
            const hour = typeof d[4] === 'string' ? Number(d[4]) : 0
            const minutes = typeof d[5] === 'string' ? Number(d[5]) : 0
            const seconds = typeof d[6] === 'string' ? Number(d[6]) : 0
            const milliseconds = Number((d[7] || '0').substring(0, 3))
            if (utc) {
                return new Date(
                    Date.UTC(
                        year,
                        month,
                        day,
                        hour,
                        minutes,
                        seconds,
                        milliseconds
                    )
                )
            }
            return new Date(
                year,
                month,
                day,
                hour,
                minutes,
                seconds,
                milliseconds
            )
        }
    }

    return new Date(date)
}

/**
 * Checks if a date object is considered valid
 * @param d Date object
 * @returns true if the date is considered valid, false otherwise
 */
export const isValidDate = (d: Date) => d.toString() !== INVALID_DATE_STRING

/**
 * Formatted string representation of provided date object
 * @param d Date to format
 * @param formatStr String representation of how the date should be represented
 * @returns String representing the provided date
 */
export const format = (d: Date, formatStr = 'YYYY-MM-DD'): string => {
    const $y = d.getFullYear()
    const $M = d.getMonth()
    const $D = d.getDate()
    const $W = d.getDay()
    const $H = d.getHours()
    const $m = d.getMinutes()
    const $s = d.getSeconds()
    const $ms = d.getMilliseconds()
    const zoneStr = padZoneStr(d)

    const getShort = (
        arr: string[],
        index: number,
        full?: string[],
        length?: number
    ) => (arr && arr[index]) || full[index].slice(0, length)

    const get$H = (num) => padStart($H % 12 || 12, num, '0')

    const meridiemFunc = (hour, minute, isLowercase) => {
        const m = hour < 12 ? 'AM' : 'PM'
        return isLowercase ? m.toLowerCase() : m
    }

    const matches = {
        YY: String(d.getFullYear()).slice(-2),
        YYYY: d.getFullYear(),
        M: $M + 1,
        MM: padStart($M + 1, 2, '0'),
        MMM: getShort(MONTHS_SHORT, $M, MONTHS, 3),
        MMMM: getShort(MONTHS, $M),
        D: d.getDate(),
        DD: padStart(d.getDate(), 2, '0'),
        d: String(d.getDay()),
        dd: getShort(WEEKDAYS_MIN, $W, WEEKDAYS, 2),
        ddd: getShort(WEEKDAYS_SHORT, $W, WEEKDAYS, 3),
        dddd: WEEKDAYS[$W],
        H: String($H),
        HH: padStart($H, 2, '0'),
        h: get$H(1),
        hh: get$H(2),
        a: meridiemFunc($H, $m, true),
        A: meridiemFunc($H, $m, false),
        m: String($m),
        mm: padStart($m, 2, '0'),
        s: String($s),
        ss: padStart($s, 2, '0'),
        SSS: padStart($ms, 3, '0'),
        Z: zoneStr // 'ZZ' logic below
    }

    return formatStr.replace(
        REGEX_FORMAT,
        (match, $1) => $1 || matches[match] || zoneStr.replace(':', '')
    ) // 'ZZ'
}

/**
 * Chec if the date string provided is the current local date
 * @param dateString string representation of the date
 * @returns true if the dates represented is the same as the current date, false otherwise
 */
export const isToday = (dateString: string) =>
    format(parseDate(dateString)) === format(new Date())

/**
 * Add number of days to date
 * @param date Date to add days to
 * @param numDays number of days to add
 * @returns new Date object
 */
export const addDays = (date: Date, numDays: number): Date => {
    const clonedDate = new Date(date)
    clonedDate.setDate(date.getDate() + numDays)
    return clonedDate
}

/**
 * Subtract number of days from date
 * @param date Date to subtract days from
 * @param numDays number of days to subtract
 * @returns new Date object
 */
export const subtractDays = (date: Date, numDays: number) => {
    return addDays(date, numDays * -1)
}

/**
 * Calculate diff in number of days between dates, returning a negative
 * number if date2 is after date1. Note that this calculates the number
 * of 24 hour periods elapsed between between date1 and date2, so where
 * the time of the Date should be ignored, stripping of times should be
 * handled prior to using this function
 * @param date1 First date
 * @param date2 Second date
 * @returns number of days between dates
 */
export const diffDays = (date1: Date, date2: Date) => {
    return Math.floor(
        (date1.getTime() - date2.getTime()) / MILLISECONDS_IN_A_DAY
    )
}
