import { useState, useEffect } from 'react'
import {
    isFeatureEnabled,
    getFeatureConfig,
    CustomTargetingFieldsType
} from './index'
import { addTrackingLabel } from '../DataDog'

export function useIsFeatureEnabled(
    feature: string,
    defaultValue: boolean | null = false,
    checkUser = false,
    extraCustomFields?: CustomTargetingFieldsType
): Boolean | string {
    const [isFlagEnabled, setIsFlagEnabled] = useState<Boolean | string>(
        () => defaultValue
    )
    useEffect(() => {
        async function checkIsFeatureEnabled() {
            const isEnabled = await isFeatureEnabled(
                feature,
                defaultValue,
                checkUser,
                extraCustomFields
            )
            addTrackingLabel(feature, isEnabled)
            setIsFlagEnabled(isEnabled)
        }
        checkIsFeatureEnabled()
    }, [feature, defaultValue, checkUser, extraCustomFields])
    return isFlagEnabled
}

export function useGetFeatureConfig<T>(
    feature,
    defaultValue: T | boolean | null = false,
    checkUser = false,
    extraCustomFields?: CustomTargetingFieldsType
): T {
    const [featureConfig, setFeatureConfig] = useState<T>(null)

    useEffect(() => {
        async function getAsyncFeatureConfig() {
            const featureConfig = await getFeatureConfig<T>(
                feature,
                defaultValue,
                checkUser,
                extraCustomFields
            )
            setFeatureConfig(featureConfig)
        }
        getAsyncFeatureConfig()
    }, [feature, defaultValue, checkUser, extraCustomFields])
    return featureConfig
}
