import React, { useEffect, useState, PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import { useIsFeatureEnabled } from '../FeatureFlags/FeatureFlagHooks'
import { getBestFitLocale, Messages, MessagesLoaders } from '../index'
import store from '../../store'

interface InternationalizedApplicationProps {
    messagesLoaders?: MessagesLoaders
}

function extractTranslateLoadTranslations(
    resolvedMessages: PromiseSettledResult<Messages>[]
) {
    const extractedTranslations = resolvedMessages
        .filter((m) => m.status === 'fulfilled')
        .map((m) => (m as any).value)

    const transformedTranslations = extractedTranslations.map((translation) => {
        if (translation !== undefined) {
            for (const [key, value] of Object.entries(translation)) {
                if (translation[key].string) {
                    translation[key] = translation[key].string
                }
            }
        }
        return translation
    })

    return transformedTranslations.length === 1
        ? transformedTranslations[0]
        : Object.assign({}, ...transformedTranslations)
}

export const InternationalizedApplication: React.FC<
    PropsWithChildren<InternationalizedApplicationProps>
> = ({ children, messagesLoaders = [] }) => {
    const i18nFlagEnabled = useIsFeatureEnabled('i18n', false, true)
    const [messages, setMessages] = useState<Messages>(null)
    const locale = getBestFitLocale()
    const i18nEnabled = messagesLoaders.length > 0 && i18nFlagEnabled
    const state = store.getState() ?? null

    useEffect(() => {
        if (i18nEnabled) {
            //TODO: remove zh-Hans condition after Transifex update
            const messageLoaderPromises = messagesLoaders.map((messageLoader) =>
                messageLoader(
                    locale != 'zh-Hans' ? locale.replace('-', '_') : locale
                )
            )
            Promise.allSettled(messageLoaderPromises).then((res) =>
                setMessages(extractTranslateLoadTranslations(res))
            )
        }
    }, [i18nEnabled, locale, messagesLoaders])

    if (messages && i18nEnabled) {
        return (
            <IntlProvider
                messages={messages}
                locale={locale}
                defaultLocale="en-US"
                timeZone={state?.settings.timezone}
            >
                {children}
            </IntlProvider>
        )
    }

    return (
        <IntlProvider
            locale={locale}
            defaultLocale="en-US"
            timeZone={state?.settings.timezone}
        >
            {children}
        </IntlProvider>
    )
}
