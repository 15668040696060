import { captureException } from '../utils/Sentry'
import { HybridMessage } from './shared'
import { isRunningInReactNativeWebView } from './detectWebview'

const windowObj = window as any

export function sendMessageToReactNativeWebview(message: HybridMessage) {
    try {
        const stringifiedMessage = JSON.stringify(message)
        if (windowObj.ReactNativeWebView) {
            windowObj.ReactNativeWebView.postMessage(stringifiedMessage)
        }
    } catch (error) {
        captureException(error, {
            message: `Failed to post ${message?.type} message to React Native webview`
        })
    }
}

export function postMessageToWebview(message: HybridMessage) {
    if (isRunningInReactNativeWebView()) {
        sendMessageToReactNativeWebview(message)
    }
}
