import { AuthState } from '../store/slices/auth'

function selectIsLoggedIn(authState: AuthState): boolean {
    if (!authState) {
        return false
    }
    const isMsTeamsAuth = authState.authType === 'azuread'
    if (!isMsTeamsAuth) {
        return Boolean(authState.isLoggedIn) && !!authState.accessToken
    }

    // MS Teams uses thrive tokens
    return Boolean(authState.isLoggedIn) && !!authState.thriveToken
}

export default selectIsLoggedIn
