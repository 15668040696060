type UseIsDemoModeProps = { url: string }
const defaultUseIsDemoModeProps: UseIsDemoModeProps = { url: '' }

type UseIsDemoModeReturn = {
    isDemoMode: boolean
    dciSurveyId: string
    journeyDailyStepId: string
}

// HOW TO USE
//
// const {
//   isDemoMode,
//   dciSurveyId,
//   journeyDailyStepId
// } = useIsDemoMode(window.location.href)

export const useIsDemoMode = ({
    url: rawUrl
} = defaultUseIsDemoModeProps): UseIsDemoModeReturn => {
    const url = new URL(rawUrl)
    const params = new URLSearchParams(url.search)

    const isDemoMode = Boolean(params.get('demo'))
    const dciSurveyId = params.get('dci_survey_id')
    const journeyDailyStepId = params.get('journey_daily_step_id')

    // this is a final failsafe; if demo=true is present without
    // either of the IDs, we should treat it as if demo=false
    const demoModeParamsPresent =
        Boolean(dciSurveyId) && Boolean(journeyDailyStepId)

    return {
        isDemoMode: isDemoMode && demoModeParamsPresent,
        dciSurveyId,
        journeyDailyStepId
    }
}
