import { useCallback } from 'react'

export const useGenerateTeamsUnfurlLink = () =>
    useCallback((path?: string) => {
        const url = new URL(`${window.location.origin}/teams/deeplink`)

        if (path) {
            url.searchParams.append('redirect', path)
        }

        return url
    }, [])
