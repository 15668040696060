import {
    ApolloClient,
    createHttpLink,
    from,
    InMemoryCache
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { InMemoryCacheConfig } from '@apollo/client/cache/inmemory/types'
import { setContext } from '@apollo/client/link/context'
import store, { AuthState } from '../../store'
import { getBestFitLocale } from '../Intl'
import { SentryLink } from 'apollo-link-sentry'
import { captureMessage } from '../Sentry'

function getBearerToken(authState: AuthState) {
    const accessToken = authState.accessToken
    const thriveToken = authState.thriveToken
    if (!accessToken && thriveToken) {
        captureMessage(
            'User has thrive token in Redux but does not have an access token set'
        )
    }
    return accessToken || thriveToken
}

export const getApolloClient = (
    cacheOptions?: InMemoryCacheConfig,
    name?: string
): ApolloClient<any> => {
    try {
        const httpLink = createHttpLink({
            uri: process.env.APOLLO_GATEWAY
        })

        const authLink = setContext((_, { headers }) => {
            // get the authentication token from local storage if it exists
            const state = store.getState()
            const bearerToken = getBearerToken(state.auth)
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    authorization: `Bearer ${bearerToken}`
                }
            }
        })

        const i18nLink = setContext((_, { headers }) => ({
            headers: {
                ...headers,
                'Thrive-Locale': getBestFitLocale()
            }
        }))

        const timezoneLink = setContext((_, { headers }) => {
            let timezone
            try {
                timezone =
                    store.getState().settings?.timezone ||
                    Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch (_error) {
                timezone = 'Etc/UTC'
            }

            return {
                headers: {
                    ...headers,
                    'Thrive-Timezone': timezone
                }
            }
        })

        const sentryLink = new SentryLink({
            attachBreadcrumbs: {
                includeQuery: true,
                includeVariables: true,
                includeFetchResult: false,
                includeError: true
            }
        })

        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (networkError) {
                if (networkError.name === 'AbortError') {
                    console.warn('GraphQL request was aborted', networkError)
                } else {
                    console.error('Network error', networkError)
                }
            }

            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.error(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    )
                )
            }
        })

        return new ApolloClient({
            cache: new InMemoryCache(cacheOptions),
            link: from([
                authLink,
                i18nLink,
                timezoneLink,
                sentryLink,
                httpLink,
                errorLink
            ]),
            name: name || 'thrive-web',
            connectToDevTools: true
        })
    } catch (error) {
        console.warn(
            'Failed to instantiate Apollo Client, this is likely because you are running in node and fetch does not exist on the global object',
            error
        )
    }
}

export const apolloClient = getApolloClient()
