import { Parser } from './types'
import parseItem from './parseItem'

export const getStorageItem = <T>(
    key: string,
    storage: Storage,
    parser: Parser<T>
): T | null => {
    if (!storage) {
        return null
    }

    let item: string
    try {
        item = storage.getItem(key)
    } catch {
        // ignore
        return null
    }

    return parseItem(item, parser)
}

export default getStorageItem
