import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChallengeTypes } from '../../enums/challengeTypes'
import { UnifiedChallenge } from '../../graphql/generated/autogenerated'

const messages = defineMessages({
    prizeChallenge: {
        defaultMessage: 'prize challenge'
    }
})

export const useGetCategory = (challenge: UnifiedChallenge) => {
    const intl = useIntl()

    return useMemo(() => {
        switch (challenge?.type?.toUpperCase()) {
            case ChallengeTypes.journey: {
                return challenge?.category?.shortName
                    ? challenge?.category?.shortName?.toLocaleUpperCase(
                          intl.locale
                      )
                    : challenge?.category?.name?.toLocaleUpperCase(intl.locale)
            }
            case ChallengeTypes.biotype: {
                return challenge?.biotype?.name?.toLocaleUpperCase(intl.locale)
            }
            case ChallengeTypes.prize: {
                return intl
                    .formatMessage(messages.prizeChallenge)
                    ?.toLocaleUpperCase(intl.locale)
            }
        }
    }, [challenge, intl])
}

export default useGetCategory
