// Below we need to cast window as 'any' since webkit is non-standard property
const windowObj = window as any

export function isRunningInReactNativeWebView(): boolean {
    return windowObj.isRunningInWebView
}

export function isRunningInWebview(): boolean {
    return windowObj.isRunningInWebView
}
