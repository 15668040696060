import { MAX_VOLUME, MIN_VOLUME } from './constants'

export const normalizeVolume = (volume: number | null | undefined): number => {
    if (isNaN(volume) || volume === null || volume === undefined) {
        return MIN_VOLUME
    }

    return Math.max(MIN_VOLUME, Math.min(MAX_VOLUME, volume))
}

export default normalizeVolume
