import { useCallback, useMemo } from 'react'
import { useLocalStorage } from '../useStorage'
import { DEFAULT_VOLUME, VOLUME_STORAGE_KEY } from './constants'
import normalizeVolume from './normalizeVolume'

export type UseVolumeResult = [number, (volume: number) => void, () => void]

export function useVolume(): UseVolumeResult {
    const [volume, setVolume, resetVolume] = useLocalStorage(
        VOLUME_STORAGE_KEY,
        DEFAULT_VOLUME
    )

    const handleSetVolume = useCallback(
        (newVolume: number) => {
            const normalizedVolume = normalizeVolume(newVolume)
            setVolume(normalizedVolume)
        },
        [setVolume]
    )

    const handleResetVolume = useCallback(() => resetVolume(), [resetVolume])

    return useMemo(
        () => [volume, handleSetVolume, handleResetVolume],
        [volume, handleSetVolume, handleResetVolume]
    )
}

export default useVolume
