import { useEffect, useRef } from 'react'

export function setPageTitle(title) {
    const currentTitle = document.title
    if (!title || currentTitle === title) {
        return
    }
    document.title = title
}

export function useSetPageTitle(title) {
    const originalTitle = useRef(null)
    useEffect(() => {
        originalTitle.current = document.title
        return () => {
            setPageTitle(originalTitle.current)
        }
    }, [])

    useEffect(() => {
        setPageTitle(title)
    }, [title])
}
