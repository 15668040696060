import { Parser } from './types'

export const parseItem = <T>(
    item: string | null,
    parser: Parser<T>
): T | null => {
    if (item === null) {
        return null
    }

    try {
        return parser(item)
    } catch {
        // ignore
        return null
    }
}

export default parseItem
