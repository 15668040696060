import type * as Branch from 'branch-sdk'
import { useEffect, useState } from 'react'
import { captureException } from '../../utils'
import { useAppSelector, useAppDispatch } from '../../store'
import { setBranchInitialized } from '../../store/slices/globalAppVariables'

export interface WindowWithBranch extends Window {
    branch: Branch
}

declare let window: WindowWithBranch

export const useBranch = () => {
    const dispatch = useAppDispatch()
    const [error, setError] = useState()
    const [branch, setBranch] = useState<Branch>(window.branch)
    const state = useAppSelector((state) => state.globalAppVariables)
    useEffect(() => {
        const initializeBranch = async () => {
            try {
                // Importing will set the branch object on the window
                await import('branch-sdk')
                const options = { no_journeys: true }
                await window.branch.init(
                    process.env.BRANCH_KEY,
                    options,
                    function (error, data) {
                        if (!error && data) {
                            setBranch(window.branch)
                            dispatch(setBranchInitialized(true))
                        } else {
                            dispatch(setBranchInitialized(false))
                            setError(error)
                            captureException(error, {
                                message: 'Branch initialization failed.'
                            })
                        }
                    }
                )
            } catch (error) {
                captureException(error, {
                    message: 'Failed to initialize Branch'
                })
            }
        }
        if (!state?.branchInitialized) {
            initializeBranch()
        }
    }, [])

    return { branch, error, branchInitialized: state?.branchInitialized }
}
