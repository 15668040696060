import { navigateToUrl } from 'single-spa'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useCrossAppNavigation = <HistoryState>() => {
    const history = useHistory()
    return useCallback(
        async (path: string, state?: HistoryState) => {
            if (!state) {
                navigateToUrl(path)
            } else {
                history.push(path, state)
            }
        },
        [history]
    )
}

export default useCrossAppNavigation
