export enum THEME {
    THRIVE = 'THRIVE',
    // LIGHT = 'LIGHT',
    DARK = 'DARK',
    HIGH_CONTRAST = 'HIGH_CONTRAST',
    NATURE = 'NATURE',
    CAMPBWELL = 'CAMP_B-WELL',
    DEMO = 'DEMO'
}

export const THEMES = {
    THRIVE: THEME.THRIVE,
    // LIGHT: THEME.LIGHT,
    DARK: THEME.DARK,
    HIGH_CONTRAST: THEME.HIGH_CONTRAST,
    NATURE: THEME.NATURE,
    CAMPBWELL: THEME.CAMPBWELL,
    DEMO: THEME.DEMO
}

export const MSTEAMS_THEMES = {
    default: THEME.THRIVE,
    dark: THEME.DARK,
    contrast: THEME.HIGH_CONTRAST
}
