import {
    Action,
    configureStore,
    EnhancedStore,
    ThunkAction
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { CombinedState, combineReducers, Reducer } from 'redux'
import { loadState, saveState } from '../utils/localStorage'
import {
    AchievementState,
    AnnouncementState,
    AuthState,
    HybridState,
    NotificationState,
    reducers,
    SettingsState,
    UserState,
    WalletState,
    GardenState,
    BreadcrumbState
} from './slices'
import { globalAppState } from './slices/globalAppVariables'

type StoreWithInject<T extends {}> = T & {
    injectSlice?: <S>(reducer: { [name: string]: Reducer<S> }) => void
}

type CoreState = CombinedState<{
    auth: AuthState
    user: UserState
    settings: SettingsState
    announcement: AnnouncementState
    achievement: AchievementState
    wallet: WalletState
    hybrid: HybridState
    notification: NotificationState
    garden: GardenState
    breadcrumbs: BreadcrumbState
}>
// extending CoreState here as store could be injected with new slice
type CoreStateWithInject = CoreState & { [name: string]: any }
interface WindowWithStore extends Window {
    THRIVE_REDUX_STORE: any
    ReactNativeWebView: any
}
declare let window: WindowWithStore
const THRIVEGLOBAL_STATE = 'THRIVEGLOBAL_STATE'
const store: StoreWithInject<EnhancedStore<CoreStateWithInject>> =
    configureStore({
        reducer: combineReducers(reducers),
        preloadedState: loadState(THRIVEGLOBAL_STATE),
        devTools: true
    })
// This is used for easy access to the store in the safari webview debugger
window.THRIVE_REDUX_STORE = store
store.subscribe(() => {
    if (window?.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                eventName: 'SAVE_CLIENT_REDUX_STORE',
                eventValue: JSON.stringify(store.getState())
            })
        )
    }

    saveState(THRIVEGLOBAL_STATE, {
        ...store.getState(),
        globalAppVariables: globalAppState
    })
})

store.injectSlice = (reducer) => {
    const updatedReducers = combineReducers({ ...reducers, ...reducer })
    store.replaceReducer(updatedReducers)
}

export type AppDispatch = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<CoreStateWithInject> =
    useSelector
export { CoreStateWithInject as CoreState }
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    CoreStateWithInject,
    unknown,
    Action<string>
>
export default store
