import store from '../../../store'

interface WindowWithPendo extends Window {
    pendo: {
        initialize: (args: any) => void
    }
}

declare let window: WindowWithPendo

interface AnalyticsUser {
    analyticsId: string
    companyId: string
}

export const initializePendo = (uniqueId: string, accountId: string) => {
    // as per pendo's documentation, it should be initialized in all environments as soon as userId is available.
    // We should exclude automated testing if this code ever runs through it and is noisy.
    if (window['pendo']) {
        window['pendo'].initialize({
            visitor: {
                id: uniqueId
            },
            account: {
                id: accountId
            }
        })
    }
}

export const setupPendo = () => {
    // Should get imported in an Application component to start early. Since we don't know when the user will
    // be available, we use a store subscription.

    const initialState = store?.getState() ?? null

    const userIsReadyForPendo = (user: AnalyticsUser): boolean =>
        Boolean(user.analyticsId && user.companyId)

    function userSetup(user: AnalyticsUser) {
        if (userIsReadyForPendo(user)) {
            initializePendo(user.analyticsId, user.companyId)
        }
    }

    if (initialState && initialState.user.analyticsId !== null) {
        userSetup(initialState.user)
    } else {
        const storeUnsubscribe = store?.subscribe(() => {
            const user = store.getState().user

            if (user.isLegacy) {
                storeUnsubscribe()
            } else if (userIsReadyForPendo(user)) {
                userSetup(user)
                storeUnsubscribe()
            }
        })
    }
}
