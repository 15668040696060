import { createSlice } from '@reduxjs/toolkit'

export type Breadcrumb = {
    label: string
    href?: string
    onClick?: () => void
}

export type BreadcrumbState = Breadcrumb[]

export const breadcrumbSlice = createSlice({
    name: 'breadcrumbs',
    initialState: [],
    reducers: {
        setBreadcrumbs: (_, { payload }) => payload,
        removeBreadcrumbs: () => []
    }
})

export const { setBreadcrumbs, removeBreadcrumbs } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer
