import { DateTense } from '../../enums/dateTense'
import {
    UnifiedChallengeActivity,
    UnifiedMicrostep,
    UnifiedMicrostepActivities
} from '../../graphql/generated/autogenerated'
import { diffDays, format, isToday, parseDate } from '../Date'

export function getIsChallengeComplete(
    participation: UnifiedChallengeActivity,
    microsteps: UnifiedMicrostep[]
) {
    return (
        getIsChallengeCompletedAndNotAcknowledged(participation) &&
        getIsChallengeOnTheLastDayAndCheckInComplete(participation, microsteps)
    )
}

// A challenge is considered completed when the expiredOn data is populated
// When the leave date is populated this is when the user has acknowledged the challenge is completed
// We usually stop showing this challenge in active state at this point
export function getIsChallengeCompletedAndNotAcknowledged(
    participation: UnifiedChallengeActivity
): boolean {
    return (
        participation !== undefined &&
        participation !== null &&
        !participation?.completedOn &&
        participation?.expiredOn !== undefined &&
        participation?.expiredOn !== null &&
        (new Date().getTime() >=
            new Date(participation?.expiredOn?.substring(0, 10))?.getTime() ||
            format(new Date(), 'YYYY-MM-DD') ==
                participation?.expiredOn?.substring(0, 10))
    )
}

export function getIsChallengeOnTheLastDayAndCheckInComplete(
    participation: UnifiedChallengeActivity,
    microsteps: UnifiedMicrostep[]
): boolean {
    const checkedInSteps = getCheckedInMicrosteps(
        participation?.microstepActivities,
        microsteps
    )
    const singleCheckInToday =
        checkedInSteps.microstepsCheckedInToday.length > 0

    const endsToday =
        !!participation?.expiredOn &&
        isToday(participation?.expiredOn.split('T')[0])

    const hasExpired =
        !!participation?.expiredOn &&
        diffDays(
            parseDate(format(new Date())),
            parseDate(participation?.expiredOn.split('T')[0])
        ) > 0

    return hasExpired || (endsToday && singleCheckInToday)
}

export function getDateTense(date: Date): DateTense {
    if (date) {
        date = new Date(date)
        const dateToBeCompared = new Date(
            Date.UTC(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate()
            )
        )
        const now = new Date()
        const today = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
        )

        if (dateToBeCompared.getTime() < today.getTime()) {
            return DateTense.past
        } else if (dateToBeCompared.getTime() === today.getTime()) {
            return DateTense.present
        } else if (dateToBeCompared.getTime() > today.getTime()) {
            return DateTense.future
        }
    }

    return DateTense.none
}

// Quick fix to ensure all today stamps (YYYY-MM-DD) are on the same
function isCheckinOnToday(checkDate: String): Boolean {
    const todaySubstring = format(new Date(), 'YYYY-MM-DD')
    return checkDate.substring(0, 10) === todaySubstring
}

export function getCheckedInMicrosteps(
    activities: UnifiedMicrostepActivities[],
    microsteps: UnifiedMicrostep[]
): {
    microstepsCheckedInToday: UnifiedMicrostep[]
    uncheckedMicrosteps: UnifiedMicrostep[]
} {
    let microstepsCheckedInToday = []
    let uncheckedMicrosteps = []
    const activitiesCompletedToday: UnifiedMicrostep[] = []

    if (activities?.length > 0) {
        for (const activity of activities) {
            const checkIns = activity?.checkIns
            const checkInArrLength = checkIns?.length

            for (let i = 0; i < checkInArrLength; i++) {
                if (isCheckinOnToday(checkIns[i])) {
                    activitiesCompletedToday.push(activity.microstep)
                    break
                }
            }
        }
    }

    if (microsteps?.length > 0) {
        ;[microstepsCheckedInToday, uncheckedMicrosteps] = microsteps.reduce(
            ([mcit, um], ms) =>
                activitiesCompletedToday.some((mc) => mc.id === ms.id)
                    ? [[...mcit, ms], um]
                    : [mcit, [...um, ms]],
            [[], []]
        )
    }

    return { microstepsCheckedInToday, uncheckedMicrosteps }
}

export function getCheckInInformation(
    microstepActivities: UnifiedMicrostepActivities[]
) {
    const uniqueCheckInDates: any[] = []
    let hasCheckedInToday = false
    let totalCheckinsCount = 0

    microstepActivities?.forEach((activity) => {
        const checkIns = activity?.checkIns
        const checkInArrLength = checkIns?.length

        for (let i = 0; i < checkInArrLength; i++) {
            const checkInDate = checkIns[i].substring(0, 10)

            if (!uniqueCheckInDates.some((u) => u == checkInDate)) {
                uniqueCheckInDates.push(checkInDate)
                hasCheckedInToday =
                    hasCheckedInToday ||
                    checkInDate == format(new Date(), 'YYYY-MM-DD')
            }

            totalCheckinsCount++
        }
    })

    return {
        uniqueCheckInDates,
        totalCheckinsCount,
        hasCheckedInToday
    }
}

export function getUserDateTimeZoneOffset(date: Date): Date {
    const dateWithoutTimezone = new Date(date).toISOString().replace('Z', '')
    return new Date(dateWithoutTimezone)
}
