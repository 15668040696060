import { createSlice } from '@reduxjs/toolkit'

export enum AuthType {
    cognito = 'cognito',
    okta = 'okta',
    oktazp = 'oktazp',
    azuread = 'azuread',
    identity = 'identity',
    identity_sso = 'identity_sso',
    identity_internal = 'identity_internal',
    calls = 'calls',
    unknown = 'unknown'
}

export type AuthState = {
    isLoggedIn: boolean | null
    serverSessionExpired: boolean | null
    tokenExpiration: number | null
    tokenId: string | null
    accessToken: string | null
    accessTokenExpiration: number | null
    lastLoggedIn: number | null
    issuer: string | null
    idp: string | null
    clientId: string | null
    authType: AuthType | null
    thriveToken: string | null
    thriveTokenVersion: string | null
    refreshToken: string | null
    refreshTokenExpiration: number | null
    tokenRefreshInProgress: boolean
    subscriptionId: string | null
    subscriptionType: string | null
    license: string | null
}

const initialState: AuthState = {
    isLoggedIn: null,
    serverSessionExpired: null,
    tokenExpiration: null,
    tokenId: null,
    accessToken: null,
    accessTokenExpiration: null,
    lastLoggedIn: null,
    issuer: null,
    clientId: null,
    idp: null,
    authType: null,
    thriveToken: null,
    thriveTokenVersion: null,
    refreshToken: null,
    refreshTokenExpiration: null,
    tokenRefreshInProgress: false,
    subscriptionId: null,
    subscriptionType: null,
    license: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuthState: (state) => {
            Object.assign(state, initialState)
        },
        authSetType: (state, { payload }: { payload: AuthType }) => {
            state.authType = payload
        },
        authIsLoggedIn: (state, { payload }) => {
            state.isLoggedIn = payload
        },
        authSetTokenExpiration: (state, { payload }) => {
            state.tokenExpiration = payload
        },
        authSetAccessTokenExpiration: (state, { payload }) => {
            state.accessTokenExpiration = payload
        },
        authSetTokenId: (state, { payload }) => {
            state.tokenId = payload
        },
        authSetLastLoggedIn: (state, { payload }) => {
            state.lastLoggedIn = payload
        },
        authSetAccessToken: (state, { payload }) => {
            state.accessToken = payload
        },
        authSetIssuer: (state, { payload }) => {
            state.issuer = payload
        },
        authSetClientId: (state, { payload }) => {
            state.clientId = payload
        },
        authSetIdp: (state, { payload }) => {
            state.idp = payload
        },
        authSetThriveToken: (state, { payload }) => {
            state.thriveToken = payload
        },
        authSetThriveTokenVersion: (state, { payload }) => {
            state.thriveTokenVersion = payload
        },
        authSetRefreshToken: (state, { payload }) => {
            state.refreshToken = payload
        },
        authSetRefreshTokenExpiration: (state, { payload }) => {
            state.refreshTokenExpiration = payload
        },
        authSetServerSessionExpired: (state, { payload }) => {
            state.serverSessionExpired = payload
        },
        authSetTokenRefreshInProgress: (state, { payload }) => {
            state.tokenRefreshInProgress = payload
        },
        authSetSubscriptionId: (state, { payload }) => {
            state.subscriptionId = payload
        },
        authSetSubscriptionType: (state, { payload }) => {
            state.subscriptionType = payload
        },
        authSetLicense: (state, { payload }) => {
            state.license = payload
        }
    }
})

export const {
    authSetType,
    authIsLoggedIn,
    authSetTokenExpiration,
    authSetTokenId,
    authSetAccessToken,
    authSetLastLoggedIn,
    authSetClientId,
    authSetIssuer,
    authSetIdp,
    authSetThriveToken,
    authSetAccessTokenExpiration,
    authSetThriveTokenVersion,
    authSetRefreshToken,
    authSetRefreshTokenExpiration,
    authSetServerSessionExpired,
    resetAuthState,
    authSetTokenRefreshInProgress,
    authSetSubscriptionId,
    authSetSubscriptionType,
    authSetLicense
} = authSlice.actions

export default authSlice.reducer
