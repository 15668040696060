export enum Locale {
    EN_US = 'en-US',
    ES_419 = 'es-419',
    FR_CA = 'fr-CA',
    FR_FR = 'fr-FR',
    IT_IT = 'it-IT',
    DE_DE = 'de-DE',
    PT_BR = 'pt-BR',
    JA_JP = 'ja-JP',
    ZH_HANS = 'zh-Hans',
    EL_GR = 'el-GR',
    PSEUDO = 'pseudo'
}

export enum Language {
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
    DE = 'de',
    PT = 'pt',
    JA = 'ja',
    ZH = 'zh',
    PSEUDO = 'pseudo'
}
