import { AchievementProgress } from '../../graphql/generated/autogenerated'
import { CLAIM_ACHIEVEMENT } from '../../graphql/mutations/achievements'
import { GET_ACHIEVEMENTS } from '../../graphql/queries/achievements'
import store, {
    setAchievementCounter,
    setAchievementProgress,
    setAchievementV3Progress,
    setAchievements
} from '../../store'
import { getApolloClient } from '../ApolloClient'
import isTokenExpired from '../isTokenExpired'
import selectIsLoggedIn from '../selectIsLoggedIn'
import { captureException, fetchAndStoreWallet } from './../../utils'

export const initializeAchievements = async () => {
    return new Promise((resolve) => {
        const state = store.getState()
        if (
            selectIsLoggedIn(state.auth) &&
            !isTokenExpired(state.auth.thriveToken)
        ) {
            resolve(fetchAndStoreAchievements())
        } else {
            const unsubscribe = store.subscribe(() => {
                if (store.getState().user.userId) {
                    unsubscribe()
                    resolve(fetchAndStoreAchievements(true))
                }
            })
        }
    })
}

export const fetchAndStoreAchievements = async (
    subscribeTriggered?: boolean
) => {
    const apolloClient = getApolloClient()
    return apolloClient
        .query({
            query: GET_ACHIEVEMENTS
        })
        .then((response) => dispatchAchievements(response.data.achievements))
        .catch((err) => {
            captureException(err, {
                message: `fetchAndStoreAchievements${
                    subscribeTriggered ? ' subscribe' : ''
                } failed`
            })
        })
}

const dispatchAchievements = async (achievementData) => {
    store.dispatch(setAchievements(achievementData.getAchievementsV2))
    store.dispatch(
        setAchievementV3Progress(achievementData.achievementsV3UserProgress)
    )
    store.dispatch(
        setAchievementProgress(achievementData.getAchievementsUserProgress)
    )
    store.dispatch(
        setAchievementCounter(
            calcCounter(achievementData.getAchievementsUserProgress)
        )
    )
}

const calcCounter = (progress: AchievementProgress[]) => {
    let ua = 0
    for (const p of progress) {
        ua += p.levels.reduce(
            (acc: any, level: any) =>
                level.completedAt && !level.claimedAt ? acc + 1 : acc,
            0
        )
    }
    return ua
}

export const claimAchievement = async (
    achievementId: string,
    levels: number[]
) => {
    const apolloClient = getApolloClient()
    return apolloClient
        .mutate({
            mutation: CLAIM_ACHIEVEMENT,
            variables: { achievementId, levels }
        })
        .then(() => fetchAndStoreAchievements())
        .then(() => fetchAndStoreWallet())
        .catch((err) => {
            captureException(err, {
                message: `claimAchievement failed`
            })
        })
}
