/**
 * String returned when a parsed date is invalid
 */
export const INVALID_DATE_STRING = 'Invalid Date'

/**
 * Regex of ISO compatible date(/time) formats
 */
export const REGEX_PARSE =
    /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/

export const REGEX_FORMAT =
    /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g

export const WEEKDAYS =
    'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_')

export const WEEKDAYS_MIN = 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_')

export const WEEKDAYS_SHORT = 'Sun_Mon_Tue_Wed_Thur_Fri_Sat'.split('_')

export const MONTHS =
    'January_February_March_April_May_June_July_August_September_October_November_December'.split(
        '_'
    )

export const MONTHS_SHORT =
    'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_')
