import { getBestFitLocale } from '.'

export function attemptToSetLanguage() {
    const language = getBestFitLocale()
    if (language) {
        const htmlElement = document.querySelector('html')
        const currentLanguage = htmlElement.getAttribute('lang')
        if (currentLanguage !== language) {
            htmlElement.setAttribute('lang', language)
        }
    }
}
