import { useCallback } from 'react'

export const useGenerateTeamsDeepLink = () => {
    const getUrl = useCallback(() => {
        const teamsUrl = process.env.MSTEAMS_CLOUD_APP_URL
        const appId = process.env.MSTEAMS_APP_ID

        return new URL(`${teamsUrl}/l/entity/${appId}/home`)
    }, [])

    return useCallback(
        (path?: string) => {
            const url = getUrl()

            if (path) {
                url.searchParams.append(
                    'context',
                    JSON.stringify({ subEntityId: path })
                )
            }

            return url
        },
        [getUrl]
    )
}
