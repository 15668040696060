import type * as Branch from 'branch-sdk'
import {
    UserSubscription,
    SubscriptionType
} from '../../graphql/generated/autogenerated'

export interface SubscriptionGroupCodesPartial {
    subscription: Partial<UserSubscription>
    groupCodes: string[]
}

const filterOutTestGroupCodes = (partial: SubscriptionGroupCodesPartial) => {
    return partial?.groupCodes?.filter((code) => !code.includes('test')) || []
}

export const getUserSubscriptionGroupCode = (
    array: SubscriptionGroupCodesPartial[],
    subscriptionId: string
): string | null => {
    const userSub = array.find(
        (item) => item.subscription.id === subscriptionId
    )
    const groupCodes = filterOutTestGroupCodes(userSub)
    if (groupCodes.length === 1) {
        return groupCodes[0]
    } else {
        //if there are no codes or multiple codes,
        //we won't include a group code in the link
        return null
    }
}

export const getFriendsAndFamilyGroupCode = (
    array: SubscriptionGroupCodesPartial[]
): string | null => {
    const enabledFriendsAndFamilySubWithCodes = array.find(
        (item) =>
            item.subscription.subscriptionType ===
                SubscriptionType.FriendsAndFamily && item.subscription.enabled
    )
    if (!enabledFriendsAndFamilySubWithCodes) {
        return null
    } else {
        const groupCodes = filterOutTestGroupCodes(
            enabledFriendsAndFamilySubWithCodes
        )
        //friends and family subscription types should only have one group code
        return groupCodes?.length === 1 ? groupCodes[0] : null
    }
}

//linkData: https://help.branch.io/using-branch/docs/creating-a-deep-link#section-configure-deep-links
export const getBranchLink = async (
    branch: Branch,
    linkData: {}
): Promise<string> => {
    const linkPromise = new Promise((resolve, reject) => {
        branch.link(linkData, (error, link) => {
            if (!error && link) {
                resolve(link)
            } else {
                reject(error)
            }
        })
    })
    return linkPromise as Promise<string>
}
