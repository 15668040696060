import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ProductInfo,
    UserObjectInfo,
    UserPlantInfo
} from '../../graphql/generated/autogenerated'

export interface GardenState {
    activePlants: UserPlantInfo[]
    plantedPlants: UserPlantInfo[]
    objects: UserObjectInfo[]
    products: ProductInfo[]
    isLoading: boolean
    isActionDisabled: boolean
}

const initialState: GardenState = {
    activePlants: [],
    plantedPlants: [],
    objects: [],
    products: [],
    isLoading: true,
    isActionDisabled: false
}

export const gardenSlice = createSlice({
    name: 'garden',
    initialState,
    reducers: {
        setGardenLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setGardenActionDisabled: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isActionDisabled = payload
        },
        setActivePlants: (
            state,
            { payload }: PayloadAction<UserPlantInfo[]>
        ) => {
            state.activePlants = payload
        },
        setPlantedPlants: (
            state,
            { payload }: PayloadAction<UserPlantInfo[]>
        ) => {
            state.plantedPlants = payload
        },
        setProducts: (state, { payload }: PayloadAction<ProductInfo[]>) => {
            state.products = payload
        },
        setObjects: (state, { payload }: PayloadAction<UserObjectInfo[]>) => {
            state.objects = payload
        }
    }
})

export const {
    setGardenLoading,
    setGardenActionDisabled,
    setActivePlants,
    setPlantedPlants,
    setProducts,
    setObjects
} = gardenSlice.actions

export default gardenSlice.reducer
