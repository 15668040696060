import {
    useGetInAppMessagesQuery,
    InAppMessageDto
} from '../../graphql/generated/autogenerated'

export const useBanners = (): InAppMessageDto[] => {
    const { data, loading, error } = useGetInAppMessagesQuery()
    if (data && !loading) {
        return data.inAppMessage.getInAppMessage
    }
    if (error?.message) throw error.message
    return null
}

export const getCurrentBanners = (
    allBanners: InAppMessageDto[]
): InAppMessageDto[] => {
    const today = new Date()
    return allBanners.filter((banner) => banner.endDate < today)
}

export const getCurrentBannersBySubscription = (
    allBanners: InAppMessageDto[],
    subscription: string
): InAppMessageDto[] => {
    const today = new Date()
    return allBanners.filter(
        (banner) =>
            banner.endDate < today &&
            banner.includedSubscriptions.includes(subscription)
    )
}

export const getLatestBanner = (
    allBanners: InAppMessageDto[]
): InAppMessageDto => {
    return allBanners.reduce(
        (prev, current) =>
            new Date(prev.endDate || 0) > new Date(current.endDate || 0)
                ? prev
                : current,
        allBanners[0]
    )
}
