import { v4 as uuidv4 } from 'uuid'
import { getItem, setItem, removeItem } from '../../sessionStorage'

const sessionIdStorageKey = 'THRIVE_SEGMENT_SESSION_ID'
const sessionIdExpirationTime = 30 * 60 * 1000 // 30 minutes in ms
let sessionIdExpirationTimeout = null

const sessionExpiration = () => {
    removeItem(sessionIdStorageKey)
}

export const getSegmentSession = () => {
    if (sessionIdExpirationTimeout) clearTimeout(sessionIdExpirationTimeout)
    let sessionID = getItem(sessionIdStorageKey)

    if (!sessionID) {
        sessionID = uuidv4()
        setItem(sessionIdStorageKey, sessionID)
    }

    sessionIdExpirationTimeout = setTimeout(
        sessionExpiration,
        sessionIdExpirationTime
    )

    return sessionID
}
