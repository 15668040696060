// docs: https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage

export const getItem = (name) => {
    try {
        const data = sessionStorage.getItem(name)
        if (data === null) return undefined
        return JSON.parse(data)
    } catch (err) {
        return undefined
    }
}

export const setItem = (name, state) => {
    try {
        const data = JSON.stringify(state)
        sessionStorage.setItem(name, data)
    } catch (err) {
        console.error(err)
    }
}

export const removeItem = (name) => {
    try {
        sessionStorage.removeItem(name)
    } catch (err) {
        console.error(err)
    }
}

export const clear = () => {
    try {
        sessionStorage.clear()
    } catch (err) {
        console.error(err)
    }
}
