import { Serializer } from './types'

export const setStorageItem = <T>(
    key: string,
    item: T,
    storage: Storage,
    serializer: Serializer<T>
) => {
    if (!storage) {
        return
    }

    try {
        const serializedItem = serializer(item)

        storage.setItem(key, serializedItem)
    } catch {
        // ignore
    }
}

export default setStorageItem
