import { gql } from '@apollo/client'

export const SEND_REFERRAL_EMAIL = gql`
    mutation sendReferralEmails($input: ReferralEmailRequestInput!) {
        identity {
            user {
                sendReferralEmails(input: $input) {
                    alreadyUsers
                    failedToSendEmailTo
                }
            }
        }
    }
`
