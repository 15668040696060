import { gql } from '@apollo/client'

export const GET_WALLET_BALANCE = gql`
    query GetBalance {
        retail {
            getBalance {
                currencyCode
                amount
            }
        }
    }
`
