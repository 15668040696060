import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { BalanceInfo } from '../../graphql/generated/autogenerated'

export interface WalletState {
    balances: BalanceInfo[]
    tokens: number
    clearWalletRefetch: boolean
}

const initialState: WalletState = {
    balances: [],
    tokens: 0,
    clearWalletRefetch: false
}

export const walletSlice: Slice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setWallet: (state, { payload }: PayloadAction<BalanceInfo[]>) => {
            state.balances = payload
        },
        setTokens: (state, { payload }: PayloadAction<number>) => {
            state.tokens = payload
        },
        setClearWalletRefetch: (state, { payload }: PayloadAction<boolean>) => {
            state.clearWalletRefetch = payload
        }
    }
})

export const { setWallet, setTokens, setClearWalletRefetch } =
    walletSlice.actions as any

export default walletSlice.reducer
