export const cloudflareAchievementsURL = process.env.CF_ACHIEVEMENTS_URL
export const cloudflareChallengesURL = process.env.CF_CHALLENGES_URL
export const cloudflareLeafkitURL = process.env.CF_LEAFKIT_URL
export const cloudflareCoreURL = process.env.CF_SOURCE_URL
export const cloudflareWearablesCoreURL = process.env.CF_WEARABLES_CORE_URL
export const cloudflarePulseInsightsURL = process.env.CF_INSIGHTS_URL
export const cloudflareJourneysURL = process.env.CF_JOURNEYS_URL
export const cloudflareCentralizedNotificationsURL =
    process.env.CF_CENTRALIZED_NOTIFICATIONS_URL
export const cloudflareResetURL = process.env.CF_RESET_URL
