import QRCode from 'qrcode'
import { useBranch } from '../useBranch'
import { useCallback, useEffect, useState } from 'react'
import { type ApolloError } from '@apollo/client'
import { getBranchLink, captureException } from '../../utils'

export interface BranchLink {
    link: string
    qrCode: string
}

export const useBranchLink = (linkData: {}) => {
    const {
        branch,
        error: branchInitializeError,
        branchInitialized
    } = useBranch()

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error | ApolloError>(null)
    const [branchLink, setBranchLink] = useState<BranchLink>({
        link: '',
        qrCode: ''
    })

    const getLinks = useCallback(async () => {
        try {
            const link = await getBranchLink(branch, linkData)
            const qrCode = (await QRCode.toDataURL(link)) || ''
            setBranchLink({ link, qrCode })
        } catch (err) {
            setError(err)
            captureException(err, null, 'identity')
        }
        setLoading(false)
    }, [branch, linkData])

    useEffect(() => {
        branchInitialized && getLinks()
    }, [branchInitialized, getLinks])

    return {
        loading,
        error: error || branchInitializeError,
        branchLink
    }
}
