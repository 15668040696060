import {
    HybridEventType,
    postMessageToWebview
} from '../../mobile-communication'
import { useCallback } from 'react'

export const useSetMobileNavigationState = () => {
    return useCallback((openState: boolean) => {
        postMessageToWebview({
            type: HybridEventType.SET_NAVIGATION_STATE,
            value: { showBottomNavigationBar: openState }
        })
    }, [])
}

export default useSetMobileNavigationState
