import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import {
    AchievementProgress,
    AchievementV2,
    AchievementV3Progress
} from '../../graphql/generated/autogenerated'

export interface AchievementState {
    achievements: AchievementV2[]
    progress: AchievementProgress[]
    progressV3: AchievementV3Progress[]
    counter: number
}

const initialState: AchievementState = {
    achievements: [],
    progress: [],
    progressV3: [],
    counter: 0
}

export const achievementSlice = createSlice({
    name: 'achievement',
    initialState,
    reducers: {
        setAchievements: (
            state,
            { payload }: PayloadAction<AchievementV2[]>
        ) => {
            state.achievements = payload
        },
        setAchievementProgress: (
            state,
            { payload }: PayloadAction<AchievementProgress[]>
        ) => {
            state.progress = payload
        },
        setAchievementV3Progress: (
            state,
            { payload }: PayloadAction<AchievementV3Progress[]>
        ) => {
            state.progressV3 = payload
        },
        setAchievementCounter: (state, { payload }: PayloadAction<number>) => {
            state.counter = payload
        }
    }
})

export const {
    setAchievements,
    setAchievementProgress,
    setAchievementV3Progress,
    setAchievementCounter
} = achievementSlice.actions

export default achievementSlice.reducer
