import store from '../../store'
import type { Breadcrumb, Event, EventHint, ErrorEvent } from '@sentry/types'
import { BrowserClientOptions } from '@sentry/browser/types/client'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { getPlatform } from '../userAgent'
import * as DD from '../DataDog'

const sentryTags: any = {}

function currentPathName(): string {
    const path = location.pathname.replace(/^\/+(?!$)/, '')
    return path === '/' ? 'today' : path
}

export function sentrySetup(
    options: Partial<BrowserClientOptions> & Required<{ dsn: string }>,
    customUserId?: string,
    useNewSentry?: Boolean
) {
    options = { ...options }
    if (!options.environment) options.environment = process.env.DEPLOYMENT_ENV
    if (!options.tracesSampleRate) options.tracesSampleRate = 0.2
    if (useNewSentry) {
        options.beforeSend = (event, hint) => {
            event = addAppNameTagToEvent(event, hint) as ErrorEvent
            return event
        }
        options.dsn = process.env.SENTRY_DSN
    }
    options.tracesSampleRate = 0.2
    options.integrations = [
        new Integrations.BrowserTracing({
            beforeNavigate: (context) => {
                return location.pathname.indexOf('/learn') > -1
                    ? undefined
                    : {
                          ...context,
                          name: currentPathName()
                      }
            },
            tracingOrigins: [
                process.env.APOLLO_GATEWAY || '',
                process.env.IDENTITY_API_DOMAIN || '',
                /^\//
            ]
        })
    ]
    Sentry.init(options)
    addUserToSentry(customUserId)
    Sentry.setTag('thrive-web-core-release', process.env.GIT_REF)
    addPlatformAsTag()
}

export function addBreadcrumb(
    breadcrumb: object | Breadcrumb,
    teamName?: string
) {
    const message = (breadcrumb as Breadcrumb)?.message
    Sentry.addBreadcrumb(breadcrumb)
    if (message) {
        DD.captureEvent(message, breadcrumb, teamName)
    }
}

export function captureException(
    err: Error,
    additionInfo: any | Breadcrumb = {},
    teamName?: string
) {
    if (err instanceof Error) {
        Sentry.addBreadcrumb(additionInfo)
        Sentry.captureException(err, {
            tags: {
                'thrive-app': teamName || 'none'
            }
        })
        DD.captureError(err, additionInfo, teamName)
    }
}

export function captureMessage(
    msg: string,
    additionInfo: any | Breadcrumb = {},
    teamName?: string
) {
    Sentry.addBreadcrumb(additionInfo)
    Sentry.captureMessage(msg, {
        tags: {
            'thrive-app': teamName || 'none'
        }
    })
    DD.captureEvent(msg, additionInfo, teamName)
}

export function captureEvent(msg: string, additionInfo: any = {}) {
    Sentry.captureEvent({
        message: msg,
        extra: additionInfo
    })
    DD.captureEvent(msg, additionInfo)
}

export function addSentryTag(
    tagKey: string,
    tagValue,
    appName?: string,
    useNewSentry?: Boolean
) {
    if (useNewSentry && appName) {
        if (!sentryTags[appName] || !sentryTags[appName][tagKey]) {
            sentryTags[appName] = { ...sentryTags[appName], [tagKey]: tagValue }
        } else {
            sentryTags[appName][tagKey] = tagValue
        }
    } else {
        Sentry.setTag(tagKey, tagValue)
    }
}

function addAppNameTagToEvent(event: Event, hint: EventHint) {
    let appName = ''
    // Get the exception and get the last frame from the stacktrace
    const apps = [
        'today',
        'learn',
        'reset',
        'core',
        'challenges',
        'profile',
        'sign-in',
        'genesys'
    ]
    // if we have the tag name then use that
    if (event.tags['thrive-app'] && event.tags['thrive-app'] !== 'none') {
        const tag = event.tags['thrive-app'] as string
        apps.some((element) => {
            if (tag.includes(element)) {
                appName = 'thrive-web-' + element
            }
        })
    }
    if (!event.exception && event.message) {
        apps.some((element) => {
            if (event.message.toLowerCase().includes(element)) {
                appName = 'thrive-web-' + element
            }
        })
    }
    if (appName === '') {
        if (event.exception?.values[0]?.stacktrace?.frames) {
            const filename = event.exception?.values[0]?.stacktrace?.frames
                ?.at?.(-1)
                ?.filename.slice(0, -3)
            // Remove all the content from the name such as pages/today/guides
            const filename_extracted = filename?.substring(
                filename?.lastIndexOf('/') + 1
            )
            // Remove the last .js from the name
            appName = filename_extracted?.substring(
                filename_extracted?.lastIndexOf('.') + 1
            )
            if (!appName) {
                appName = ''
                addBreadcrumb({ message: 'failed to remove .js from filename' })
            }
        }
    }

    if (!appName.includes('thrive-web-')) {
        if (event.exception?.values[0]?.stacktrace?.frames) {
            const name =
                event.exception?.values[0]?.stacktrace?.frames[0].filename
            if (name) {
                apps.some((element) => {
                    if (name.toLowerCase().includes(element)) {
                        appName = 'thrive-web-' + element
                    }
                })
            }
        }
    }
    if (!appName.includes('thrive-web-')) {
        // fallback for when we don't have a app name
        appName = 'thrive-web-app'
    }
    // Add the tag
    event.tags['thrive-app'] = appName
    console.log('tagging event with', appName)
    // If we have other tags for this app, add them
    if (sentryTags[appName]) {
        Object.keys(sentryTags[appName]).forEach((key) => {
            event.tags[key] = sentryTags[appName][key]
        })
    }
    return event
}

export function addUserToSentry(userId?: string, companyId?: string) {
    const user = store.getState().user
    Sentry.configureScope((scope) => {
        scope.setUser({
            id: userId || user.userId,
            analyticsId: userId || user.analyticsId,
            companyId: companyId || user.companyId
        })
        scope.setExtra('companyId', companyId || user.companyId)
        scope.setTag('companyId', companyId || user.companyId)
    })
}

export function addPlatformAsTag() {
    const platform = getPlatform()
    Sentry.setTag('thrive-platform', platform)
}
