import { gql } from '@apollo/client'

export const CLAIM_ACHIEVEMENT = gql`
    mutation ClaimAchievement($achievementId: UUID!, $levels: [Int!]!) {
        achievements {
            claimAchievement(achievementId: $achievementId, levels: $levels) {
                achievementId
            }
        }
    }
`
