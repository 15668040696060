import { useEffect, useState } from 'react'
import { applicationTheme, getAllThemes, ExtendedTheme } from '../../utils'
import store, { useAppSelector } from '../../store'

export const useTheme = (): ExtendedTheme => {
    const themes = getAllThemes()
    const [theme, setTheme] = useState<ExtendedTheme>(applicationTheme)
    const settings = useAppSelector((state: any) => state.settings)
    useEffect(() => {
        setTheme(themes[settings.theme])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.theme])
    return theme
}

//For exclusive use for ThriveApplication component - redux store initialization async error
export const useThriveApplicationTheme = (): ExtendedTheme => {
    const themes = getAllThemes()
    const initialSettings = store.getState().settings
    const [theme, setTheme] = useState<ExtendedTheme>(
        themes ? themes[initialSettings.theme] : applicationTheme
    )
    const [themeName, setThemeName] = useState<string>(initialSettings.theme)

    useEffect(() => {
        store.subscribe(async () => {
            const settings = store.getState().settings

            if (themeName !== settings.theme) {
                setTheme(themes[settings.theme])
                setThemeName(settings.theme)
            }
        })
    }, [])

    return theme
}
