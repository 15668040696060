import { gql } from '@apollo/client'

export const GET_PLANTS = gql`
    query GetPlants {
        retail {
            getPlants {
                canBeWatered
                wateredAt
                userId
                updatedAt
                progressSteps
                productUpc
                plantedGardenId
                plantImage
                id
                createdAt
                colorLayer4
                colorLayer3
                colorLayer2
                colorLayer1
                challengeName
                challengeId
            }
            getObjects {
                createdAt
                gardenId
                id
                productUpc
                updatedAt
                userId
            }
            getProducts {
                updatedAt
                upc
                title
                productType
                productTypeEnum
                plantWateringSteps
                description
                currencyCode
                createdAt
                cost
            }
        }
    }
`
