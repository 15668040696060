import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserNudgesSentResult } from '../../graphql/generated/autogenerated'

export enum BrowserNotificationPermission {
    DEFAULT = 'default',
    DENIED = 'denied',
    GRANTED = 'granted'
}

export enum NotificationType {
    ERROR,
    ACHIEVEMENT,
    PLANT_WATERED
}

export interface NotificationModel {
    type: NotificationType
    payload: any
    color: 'error' | 'success' | 'info' | 'warning'
    autohide: boolean
}

export interface NotificationState {
    notification: NotificationModel
    showConfetti: boolean
    pendingNudges: UserNudgesSentResult | []
    pendingNudgesLoading: boolean
    browserNotificationPermission:
        | BrowserNotificationPermission
        | NotificationPermission
}

const initialState: NotificationState = {
    notification: undefined,
    showConfetti: true,
    pendingNudges: [],
    pendingNudgesLoading: true,
    browserNotificationPermission:
        typeof Notification !== 'undefined'
            ? Notification.permission
            : BrowserNotificationPermission.DEFAULT
}

export const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (
            state,
            { payload }: PayloadAction<NotificationModel>
        ) => {
            state.notification = payload
        },
        setPendingNudges: (
            state,
            { payload }: PayloadAction<UserNudgesSentResult>
        ) => {
            state.pendingNudges = payload
        },
        setPendingNudgesLoading: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.pendingNudgesLoading = payload
        },
        setShowConfetti: (state, { payload }: PayloadAction<boolean>) => {
            state.showConfetti = payload
        },
        setBrowserNotificationPermission: (
            state,
            {
                payload
            }: PayloadAction<
                BrowserNotificationPermission | NotificationPermission
            >
        ) => {
            state.browserNotificationPermission = payload
        }
    }
})

export const {
    setNotification,
    setPendingNudges,
    setPendingNudgesLoading,
    setShowConfetti,
    setBrowserNotificationPermission
} = notification.actions

export default notification.reducer
