import { UseStorageOptions } from './types'
import { useStorage } from './useStorage'

export const useLocalStorage = <T>(
    key: string,
    initialValue: T = null,
    options?: UseStorageOptions<T>
) => useStorage(key, initialValue, window?.localStorage, options)

export default useLocalStorage
