import {
    GetPendingNudgesQuery,
    PlatformCategory
} from '../../graphql/generated/autogenerated'
import { ADD_DEVICE } from '../../graphql/mutations/notifications'
import { GET_PENDING_NUDGES } from '../../graphql/queries/notifications'
import store, {
    BrowserNotificationPermission,
    setBrowserNotificationPermission,
    setPendingNudges,
    setPendingNudgesLoading
} from '../../store'
import { captureException, getApolloClient } from '../../utils'
import isTokenExpired from '../isTokenExpired'
import selectIsLoggedIn from '../selectIsLoggedIn'

export const initializeNotifications = async () => {
    return new Promise((resolve) => {
        const state = store.getState()
        if (
            selectIsLoggedIn(state.auth) &&
            !isTokenExpired(state.auth.thriveToken)
        ) {
            resolve(fetchAndStoreNotifications())
        } else {
            const unsubscribe = store.subscribe(() => {
                if (store.getState().user.userId) {
                    unsubscribe()
                    resolve(fetchAndStoreNotifications(true))
                }
            })
        }
    })
}

export const fetchAndStoreNotifications = async (
    subscribeTriggered?: boolean
) => {
    store.dispatch(
        setBrowserNotificationPermission(
            typeof Notification !== 'undefined'
                ? Notification.permission
                : BrowserNotificationPermission.DEFAULT
        )
    )
    store.dispatch(setPendingNudgesLoading(true))
    const apolloClient = getApolloClient()
    return apolloClient
        .query<GetPendingNudgesQuery>({
            query: GET_PENDING_NUDGES,
            variables: {
                input: {
                    isSent: null,
                    platformCategories: [PlatformCategory.Web],
                    within: null
                }
            }
        })
        .then((response) => dispatchNotifications(response.data))
        .catch((err) => {
            store.dispatch(setPendingNudgesLoading(false))
            captureException(err, {
                message: `fetchAndStoreNotifications${
                    subscribeTriggered ? ' subscribe' : ''
                } failed`
            })
        })
}

const dispatchNotifications = async (
    notificationsData: GetPendingNudgesQuery
) => {
    const pendingNudges =
        notificationsData?.centralizedNotifications?.pendingNudges
    if (pendingNudges.__typename === 'UserPendingNudgesFetchSuccess') {
        const notifications = pendingNudges.result as any
        store.dispatch(setPendingNudges(notifications))
        store.dispatch(setPendingNudgesLoading(false))
    }
}

export async function savePlayerId(oneSignalPlayerId: string) {
    try {
        const client = getApolloClient()
        await client.mutate({
            variables: {
                deviceId: oneSignalPlayerId
            },
            mutation: ADD_DEVICE
        })
    } catch (error) {
        captureException(error, {
            message: `Unable to save user's player id.`
        })
    }
}
