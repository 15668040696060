import { gql } from '@apollo/client'

export const GET_ACHIEVEMENTS = gql`
    query GetAchievements {
        achievements {
            getAchievementsV2 {
                description
                id
                image
                title
                maximumLevel
                rewardAmount
                rewardCurrencyCode
                actionType
                upc
            }
            getAchievementsUserProgress {
                achievementId
                userId
                progress
                levels {
                    level
                    total
                    completedAt
                    claimedAt
                }
            }
            achievementsV3UserProgress {
                achievementInfo {
                    actionNumber
                    actionType
                    description
                    frequency
                    id
                    name
                    rewardAmount
                    rewardCurrency
                    upc
                }
                lastCompletedAt
                lastUpdatedAt
                total
                currentProgress
                upc
            }
        }
    }
`
