import { useMemo } from 'react'
import { ChallengeTypes } from '../../enums/challengeTypes'
import { UnifiedChallenge } from '../../graphql/generated/autogenerated'
import { useIsFeatureEnabled } from '../../utils/FeatureFlags/FeatureFlagHooks'

export const useShowWalmartBetterChoicesChallenge = (
    challenge: UnifiedChallenge
): boolean => {
    const showWalmartBetterChoicesChallenge = useIsFeatureEnabled(
        'showWalmartBetterChoicesChallenge',
        false,
        true
    ) as boolean

    const showWalmartPrizeText = useMemo(
        () =>
            showWalmartBetterChoicesChallenge &&
            (challenge?.challenge_type
                ? challenge?.challenge_type?.toUpperCase() ===
                  ChallengeTypes.prize
                : challenge?.type?.toUpperCase() === ChallengeTypes.prize),
        [showWalmartBetterChoicesChallenge, challenge]
    )

    return showWalmartPrizeText
}

export default useShowWalmartBetterChoicesChallenge
