import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

export interface AnnouncementState {
    closedAnnouncements: { [key: string]: boolean }
}

const initialState: AnnouncementState = {
    closedAnnouncements: {}
}

export const announcementSlice: Slice = createSlice({
    name: 'announcement',
    initialState,
    reducers: {
        addClosedAnnouncement: (state, { payload }: PayloadAction<string>) => {
            state.closedAnnouncements[payload] = true
        }
    }
})

export const { addClosedAnnouncement } = announcementSlice.actions

export default announcementSlice.reducer
