import { useHistory } from 'react-router-dom'
import {
    HybridEventType,
    HybridNavigationTab,
    isRunningInWebview,
    postMessageToWebview
} from '../../mobile-communication'
import { useCallback } from 'react'

/**
 * DEPRECATED
 * To be deleted soon, all instances will be replaced with useCrossAppNavigation
 */
export const useNavigation = () => {
    const history = useHistory()
    return useCallback(
        (path: String, options?: any) => {
            // Temp adding in so mobile can test the messages coming through
            if (isRunningInWebview()) {
                const location = path.split('/')[1]
                if (
                    Object.values(HybridNavigationTab as any).includes(location)
                ) {
                    const tab =
                        HybridNavigationTab[
                            location as keyof typeof HybridNavigationTab
                        ]
                    postMessageToWebview({
                        type: HybridEventType.NAVIGATE,
                        value: { tab, location: path }
                    })
                }
            }
            history.push(path, options ?? {})
        },
        [history]
    )
}

export default useNavigation
