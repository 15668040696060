import { MessageFormatElement } from 'react-intl'
import store from '../../store'
import { localeMatcher } from './localeMatcher'
import { Locale, Language } from '../../enums'

const availableLocales: string[] = Object.values(Locale)
const availableLanguages: string[] = Object.values(Language)
export type WebApplicationLocale = (typeof availableLocales)[number]
export type WebApplicationLanguage = (typeof availableLanguages)[number]

export type Messages =
    | Record<string, string>
    | Record<string, MessageFormatElement[]>
export type MessagesLoaders = Array<(locale: string) => Promise<Messages>>

const languagesToDefaultLocaleMap: {
    [K in WebApplicationLanguage]: WebApplicationLocale
} = {
    en: Locale.EN_US,
    es: Locale.ES_419,
    fr: Locale.FR_CA,
    it: Locale.IT_IT,
    de: Locale.DE_DE,
    pt: Locale.PT_BR,
    ja: Locale.JA_JP,
    zh: Locale.ZH_HANS,
    pseudo: Locale.PSEUDO
}

function isWebApplicationLocale(
    locale: string
): locale is WebApplicationLocale {
    return availableLocales.includes(locale)
}

function getBestFitLocale(
    env: typeof globalThis = globalThis,
    defaultLocale: WebApplicationLocale = Locale.EN_US
) {
    const localeSetting = store.getState().settings.locale
    const systemLocales = env.navigator.languages
    const matchedLocale = localeMatcher(
        availableLanguages,
        availableLocales,
        defaultLocale,
        languagesToDefaultLocaleMap,
        env.location.search,
        env.localStorage,
        localeSetting,
        systemLocales
    )

    return languagesToDefaultLocaleMap[matchedLocale] ?? matchedLocale
}

export { isWebApplicationLocale, getBestFitLocale }
