import React, { ReactEventHandler, ReactNode } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material'

type Props = {
    open: boolean
    title: string
    content?: ReactNode | ReactNode[]
    actions?: ReactNode | ReactNode[]
    onBackdropClick?: ReactEventHandler<{}>
}

const Popup: React.FC<Props> = ({
    open,
    title,
    content,
    actions,
    onBackdropClick
}) => (
    <Dialog open={open} onBackdropClick={onBackdropClick} data-testid="popup">
        <DialogTitle
            sx={{
                pt: 4,
                pl: 4,
                pr: 4
            }}
        >
            <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <DialogContent
            sx={{
                pl: 4,
                pr: 4
            }}
        >
            {content}
        </DialogContent>
        <DialogActions
            sx={{
                pl: 4,
                pr: 4,
                pb: 3
            }}
        >
            {actions}
        </DialogActions>
    </Dialog>
)

export default Popup
