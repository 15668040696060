import { Analytics } from '@segment/analytics-next'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../store'

export const useSegment = (): Analytics => {
    const [analytics, setAnalytics] = useState<Analytics>(window.analytics)
    const state = useAppSelector((state) => state.globalAppVariables)
    useEffect(() => {
        if (!analytics && state.segmentInitialized) {
            setAnalytics(window.analytics)
        }
    }, [analytics, state.segmentInitialized])
    return analytics
}
export default useSegment
